/** @jsx jsx */
import { jsx, Box, Text } from "theme-ui"
import { graphql } from "gatsby"
import {
  AnimatedScrollPrompt,
  LayoutNoSidebar,
  ParallaxHeaderWithSvg,
  SiteLogo,
  SplitScreen,
} from "@smerth/gatsby-theme-theme-ui"
import BigMenu from "../components/BigMenu"
import Markdown from "../components/Markdown"

function StyledBox({ children, ...rest }) {
  return (
    <Box
      variant="layout.alignCenter"
      sx={{
        p: 8,
        height: "100vh",
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

function index({ data }) {
  return (
    <LayoutNoSidebar>
      <ParallaxHeaderWithSvg
        component={<SiteLogo size="inherit" />}
        sx={{ height: (theme) => `calc("100vh" - ${theme.sizes.header})` }}
      >
        <Box variant="layout.constrained" color="chromeText">
          <Text as="h1" variant="display.9" sx={{ textAlign: "center" }}>
            A Design System Starter
          </Text>
          <Box variant="layout.constrainedByPercent" percent="80%">
            <Markdown body={data.designSystemStarter.childMdx.body} />
          </Box>
        </Box>
        <AnimatedScrollPrompt
          color="chromeText"
          background="rgb(0, 0, 0, 0.25)"
          delay="1000ms"
        />
      </ParallaxHeaderWithSvg>

      <SplitScreen
        backgroundColorLeft="background"
        backgroundColorRight="chrome"
        componentLeft={
          <Box variant="layout.centered" sx={{ height: "100%" }}>
            <Markdown body={data.gatsby.childMdx.body} />
          </Box>
        }
        componentRight={
          <StyledBox color="white">
            <Markdown body={data.documentationSite.childMdx.body} />
          </StyledBox>
        }
      />

      <SplitScreen
        backgroundColorLeft="muted"
        backgroundColorRight="accent"
        componentLeft={
          <StyledBox>
            <Markdown body={data.themeUI.childMdx.body} />
          </StyledBox>
        }
        componentRight={
          <Box
            variant="layout.alignCenter"
            sx={{ p: 8, color: "text", height: "100vh" }}
          >
            <Markdown body={data.themeObject.childMdx.body} />
          </Box>
        }
      />

      <SplitScreen
        backgroundColorLeft="background"
        backgroundColorRight="background"
        componentLeft={
          <Box
            variant="layout.alignCenter"
            sx={{ p: 8, color: "text", height: ["auto", "auto", "100vh"] }}
          >
            <Markdown body={data.documentationComponents.childMdx.body} />
          </Box>
        }
        componentRight={
          <Box variant="layout.alignCenter" sx={{ p: 8, color: "text" }}>
            <Markdown body={data.reactLiveDemo.childMdx.body} />
          </Box>
        }
      />

      <Box color="secondaryText" bg="secondary" sx={{ position: "relative" }}>
        <BigMenu
          accordionVariant="transparent"
          menuVariant="secondary"
          height="xs"
        />
      </Box>
    </LayoutNoSidebar>
  )
}

export const query = graphql`
  query {
    designSystemStarter: file(
      childMdx: { frontmatter: { title: { eq: "Design System Starter" } } }
    ) {
      id
      childMdx {
        body
      }
    }
    gatsby: file(childMdx: { frontmatter: { title: { eq: "GatsbyJS" } } }) {
      id
      childMdx {
        body
      }
    }
    documentationSite: file(
      childMdx: { frontmatter: { title: { eq: "Documentation Site" } } }
    ) {
      id
      childMdx {
        body
      }
    }
    themeUI: file(childMdx: { frontmatter: { title: { eq: "ThemeUI" } } }) {
      id
      childMdx {
        body
      }
    }
    themeObject: file(
      childMdx: { frontmatter: { title: { eq: "Theme Object" } } }
    ) {
      id
      childMdx {
        body
      }
    }
    documentationComponents: file(
      childMdx: { frontmatter: { title: { eq: "Documentation Components" } } }
    ) {
      id
      childMdx {
        body
      }
    }
    reactLiveDemo: file(
      childMdx: { frontmatter: { title: { eq: "ReactLive Demo" } } }
    ) {
      id
      childMdx {
        body
      }
    }
  }
`

export default index

/** @jsx jsx */
import { jsx, Box, Text } from "theme-ui"
import SidebarDocumentation from "./SidebarDocumentation"

export default function BigMenu({
  className,
  accordionVariant,
  menuVariant,
  height,
}) {
  return (
    <Box className={className} variant="layout.constrained">
      <Text as="h1" variant="display.8" pt={6}>
        Documentation
      </Text>
      <SidebarDocumentation
        accordionVariant={accordionVariant}
        menuVariant={menuVariant}
        height={height}
        direction="row"
        noHooks
        noFunctions
        noBorder
        sx={{ py: 8 }}
      />
    </Box>
  )
}
